/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, cloneElement, ReactElement } from 'react';
import MultipleOptionsCard from './components/Cards/MultipleOptionsCard';
import Confetti from 'react-confetti';
import ReactAudioPlayer from 'react-audio-player';

import { useLocalStorage } from './hooks/useLocalStorage';
import { useToggle } from 'usehooks-ts';

import styles from './index.module.scss';
import { error } from 'console';

type Result = { error: number, ok: number, recent: string[], all: string[] };

const MAX_LIVES = 3;
const CARDS_PER_LEVEL = 10;
function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [results, setResults] = useLocalStorage<object>('results', {});
  const [maxScore, setMaxScore] = useLocalStorage<number>('maxScore', 0);
  const [nCard, setNCard] = useState<number>(0);
  const [stage, setStage] = useState<string>('start');
  const [score, setScore] = useState<number>(0);
  const [lives, setLives] = useState<number>(MAX_LIVES);
  const [level, setLevel] = useState<number>(1);
  const [levelCardsCompleted, setLevelCardsCompleted] = useState<number>(0);
  const [status, setStatus] = useState<'ok' | 'error' | null>(null);
  const [isAudioEnabled, toggleAudioEnabled] = useToggle(true);
  const [currentCard, setCurrentCard] = useState<JSX.Element | null>(null);

  const totalSecondsPerCard = Math.max(15 - (level - 1) * 3, 5);

  const addToResults = (numbers: number[], type: 'ok' | 'error') => {
    setResults((currentResults: object) => {
      let rsp = { ...currentResults };
      numbers.map((n) => {
        type OnlyKeys = keyof typeof rsp;
        const newResult: Result = n in currentResults ?
          rsp[n as OnlyKeys] :
          {
            'error': 0,
            'ok': 0,
            'recent': [],
            'all': [],
          };
        newResult[type] += 1;
        newResult.all.unshift(type);
        newResult.recent = newResult.all.slice(0, 10);
        rsp = {
          ...rsp,
          [n]: newResult,
        };
      });
      return rsp;

    });
  };


  const reduceLives = () => {
    setLives((prevLives: number) => prevLives - 1);
  };

  const nextLevel = () => {
    setLevel((prevLevel: number) => prevLevel + 1);
    setScore((prevScore: number) => prevScore + (totalSecondsPerCard * 5));
    setLevelCardsCompleted(0);
    setLives(MAX_LIVES);
  };

  const [onSuccess, onFail, generateCard] = (() => [
    (a: number, b: number, selectedValue: number | null, remainingSeconds: number) => {
      setLevelCardsCompleted((prevLevelCardsCompleted: number) => prevLevelCardsCompleted + 1);
      setStatus('ok');
      setScore((prevScore: number) => prevScore + remainingSeconds + level);
      addToResults(Array.from(new Set<number>([a, b])), 'ok');
      window.setTimeout(() => {
        generateCard();
        setStatus(null);
      }, 5000);

    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (a: number, b: number, selectedValue: number | null, remainingSeconds: number) => {
      setLevelCardsCompleted((prevLevelCardsCompleted: number) => prevLevelCardsCompleted + 1);
      setStatus('error');
      setScore((prevScore: number) => Math.max(prevScore - Math.round(totalSecondsPerCard / 2), 0));
      addToResults(Array.from(new Set<number>([a, b])), 'error');
      window.setTimeout(() => {
        setStatus(null);
        if (lives > 1) {
          generateCard();
        }
        reduceLives();
      }, 3000);
    },
    () => {
      setNCard((preV: number) => preV + 1);
      setCurrentCard(
        <MultipleOptionsCard
          status={'ok'}
          totalSeconds={10}
          a={Math.ceil(Math.random() * 8) + 1}
          b={Math.ceil(Math.random() * 8) + 1}
          onSuccess={onSuccess}
          onFail={onFail}
        />,
      );
    }])();


  const start = () => {
    setStatus(null);
    setLives(MAX_LIVES);
    setLevel(1);
    setLevelCardsCompleted(0);
    setStage('playing');
    setCurrentCard(null);
    setScore(0);
    window.setTimeout(() => {
      generateCard();
    }, 100);
  };
  const gameOver = () => {
    setStage('gameover');
    setMaxScore(Math.max(maxScore, score));
  };

  useEffect(() => {
    if (lives === 0) {
      gameOver();
    }
  }, [lives, score]);

  useEffect(() => {
    if (levelCardsCompleted === CARDS_PER_LEVEL) {
      nextLevel();
    }
  }, [levelCardsCompleted]);
  console.log('stage', stage);
  if (stage === 'start') {
    return (
      <div className={[styles.cnt].join(' ')}>
        <div className={styles.inner}>
          <div className={styles.start} >
            <img src='/images/gato.svg' alt="" className={styles.logo} />
            <div className={styles.buttonWhite} onClick={() => setStage('instructions')}>
              Empezar a jugar
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (stage === 'instructions') {
    return (
      <div className={[styles.cnt].join(' ')} onClick={start}>
        <div className={styles.inner}>
          <div className={styles.start} >
            <img src='/images/gato.svg' alt="" className={styles.logo} />
            <div className={styles.instructions}>
              <strong>Elige la opción correcta antes que se acaba el tiempo pero con atención.</strong>
              <br />
              <br />
              ¡Acumula puntos y avanza niveles. Cuanto más rápido resuelvas cada multiplicación más puntos ganarás!
            </div>
            <div className={styles.buttonWhite} onClick={start} >
              ¡A jugar!
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (stage === 'gameover') {
    return (
      <div className={[styles.cnt].join(' ')} onClick={start}>
        <div className={styles.inner}>
          <div className={styles.gameOverMessage}>
            <div className={styles.title}>
              Juego finalizado
              {score === maxScore ? <strong><br /><br />¡Nuevo record!</strong> : ''}
            </div>
            <div className={styles.info}>
              Nivel: {level}
              <br />
              Puntaje actual: {score} {maxScore > 0 && (<>
                (Máximo: {maxScore})
              </>)}

            </div>
            <table className={styles.resultTable}>
              <tr><th>Número</th><th>Correctas/Total</th><th>% correcto</th></tr>
              {[...Object.keys(results)].map((x) => (<tr key={x}>
                <td>
                  {x}
                </td>
                <td>
                  {//@ts-ignore
                    (results[x] as unknown as Result).recent.filter(y => y === 'ok').length} /
                  {//@ts-ignore
                    (results[x] as unknown as Result).recent.length}
                </td>
                <td>
                  {
                    Math.round(((
                      //@ts-ignore
                      (results[x] as unknown as Result).recent.filter(y => y === 'ok').length
                      //@ts-ignore
                    ) / (results[x] as unknown as Result).recent.length) * 100)}%
                </td>
              </tr>))}
            </table>
            <div className={styles.button} onClick={start}>
              Volver a jugar
            </div>
            {isAudioEnabled &&
              (
                <ReactAudioPlayer
                  src='/audio/end.mp3'
                  autoPlay
                />
              )
            }
          </div>

        </div>

      </div>
    );
  }

  return (
    <div className={[styles.cnt, styles[status ? status : '']].join(' ')}>
      {status === 'ok' && <Confetti />}
      <div className={[styles.inner, styles.innerApp].join(' ')}>
        <div className={styles.topbar}>
          <div className={styles.lives}>
            {Array.from(Array(MAX_LIVES).keys()).map((x, i: number) => <img key={i} src={i < lives ? '/images/heart-fill.svg' : '/images/heart-void.svg'} />)}
          </div>
          <div className={styles.score}>
            Nivel: {level}  ({levelCardsCompleted}/{CARDS_PER_LEVEL}),
            {' '}
            Puntaje: {score}
          </div>
          <div className={styles.audioToggle}
            onClick={() => toggleAudioEnabled()}
          >
            <img src={isAudioEnabled ? '/images/sound.svg' : '/images/mute.svg'} />
          </div>
        </div>

        <div className={[status === 'error' ? 'horizontalShaking' : '', styles.cardContainer].join(' ')}>
          {currentCard && cloneElement(currentCard as ReactElement,
            {
              status, key: nCard,
              totalSeconds: totalSecondsPerCard,
            })}
          <div className={styles.exitGame} onClick={gameOver}>
            Abandonar el juego
          </div>
        </div>

        {isAudioEnabled && status !== null &&
          (
            <ReactAudioPlayer
              src={status === 'ok' ? '/audio/success.mp3' : '/audio/error.mp3'}
              autoPlay
            />
          )
        }

      </div>
    </div>
  );
}

export default App;
