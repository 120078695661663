import React, { useEffect, useState } from 'react';
import { useCountdown } from 'usehooks-ts';

import stylesCard from '../index.module.scss';

type TypeStatus = 'ok' | 'error' | null;

interface MultipleOptionsCardProps {
  a: number;
  b: number;
  totalSeconds: number
  status: TypeStatus,
  onSuccess: (a: number, b: number, selectedValue: number, remainingSeconds: number) => void;
  onFail: (a: number, b: number, selectedValue: number | null, remainingSeconds: number) => void;
}

const getRandomMultiplication = (a: number | null, b: number | null, currentOptions: number[]): number => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const na = a ?? Math.ceil(Math.random() * 9);
    const nb = b ?? Math.ceil(Math.random() * 9);
    const r = na * nb;
    if (!currentOptions.includes(r)) {
      return r;
    }
  }
};
function MultipleOptionsCard({
  a,
  b,
  totalSeconds,
  status,
  onSuccess,
  onFail,
}: MultipleOptionsCardProps) {
  const [options, setOptions] = useState<number[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: (totalSeconds * 10),
      intervalMs: 100,
    });

  const correctOption: number = a * b;
  useEffect(() => {
    setSelectedOption(null);
    resetCountdown();
    startCountdown();
  }, []);
  useEffect(() => {
    if (count === 0) {
      stopCountdown();
      onFail(a, b, null, count);
    }
  }, [count]);
  useEffect(() => {
    setSelectedOption(null);
    const allOptions = [
      a * b,
    ];
    allOptions.push(getRandomMultiplication(a, null, allOptions));
    allOptions.push(getRandomMultiplication(b, null, allOptions));
    allOptions.push(getRandomMultiplication(null, null, allOptions));
    setOptions(allOptions.sort(() => 0.5 - Math.random()));
  }, [a, b]);

  const checkOptionSelected = (selectedValue: number) => {
    stopCountdown();
    if (selectedOption === null && correctOption !== null) {
      setSelectedOption(selectedValue);
      if (selectedValue === correctOption) {
        onSuccess(a, b, selectedValue, Math.round(count / 10));
      } else {
        onFail(a, b, selectedValue, Math.round(count / 10));
      }
    }

  };

  const finished = (count === 0 || selectedOption !== null);

  return (
    <div className={[stylesCard.card, stylesCard[`status_${status as string}`]].join(' ')}>
      <div className={stylesCard.countdown}>
        <div className={stylesCard.countdown} style={{
          height: '100%', position: 'absolute',
          top: 0, bottom: 0, left: 0, width: `${(1 - count / (totalSeconds * 10)) * 100}%`, background: '#444',
        }} />
      </div>
      <div className={stylesCard.question}>
        {a} x {b}
      </div>
      <div className={[
        stylesCard.options,
        finished ? stylesCard.hasSelectedOption : stylesCard.noSelectedOption,
      ].join(' ')}>
        {options.map(x =>
          <div className={[
            stylesCard.option,
            selectedOption !== null && correctOption === x ? stylesCard.ok : '',
            selectedOption !== correctOption
              && selectedOption === x ? stylesCard.error : '',
          ].join(' ')} key={x} onClick={() => {
            checkOptionSelected(x);
          }}>
            <div className={stylesCard.indicator}>
              {finished &&
                <>
                  {correctOption === x &&
                    <img src="/images/ok-check.svg" alt="ok" />
                  }
                  {selectedOption !== correctOption
                    && selectedOption === x &&
                    <img src="/images/error-check.svg" alt="ok" />
                  }
                </>
              }
            </div>
            <div className={stylesCard.value}>
              {x}
            </div>
          </div>)}
      </div>
    </div>
  );
}

export default MultipleOptionsCard;
